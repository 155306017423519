import React from "react"
import { AssignmentDot } from "../../AssignmentDot"

interface AssignmentDetailsProps {
  isDraft: boolean
  providerColor: string | null | undefined
  providerName: string
}

export const AssignmentDetails = ({
  isDraft,
  providerColor,
  providerName,
}: AssignmentDetailsProps) => (
  <>
    <AssignmentDot isDraft={isDraft} customColor={providerColor} />
    <span>{providerName}</span>
  </>
)
