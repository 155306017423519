import React, { useState } from "react"
import css from "./TabContent.module.scss"
import SearchIcon from "@material-ui/icons/Search"
import { Provider } from "@app/containers/spa/WhiteboardCalendar/data"
type TabName = "Providers" | "Jobs" | "Day Off Types"

interface Job {
  abbrev: string
  calendar_edit_lockout: number
  calendar_edit_lockout_hours: number
  created_at: string | null
  endtime: string
  facilityid: number | null
  groupid: number
  job_type: {
    job_typeid: number
    type: string
  }
  job_typeid: number
  jobid: number
  link_to_rotations: boolean
  multiple_assignments: boolean
  name: string
  overnight_call: boolean
  overnight_float: boolean
  priority: number
  show_empty_assignments: boolean
  starttime: string
  tigerconnect_integration: boolean
  tigerconnect_roles: string[]
  updated_at: string | null
}

interface DayOffType {
  abbrev: string
  type: string
}

const chunk = <T,>(array: T[], size: number): T[][] => {
  const chunked_arr: T[][] = []
  for (let i = 0; i < array.length; i += size) {
    chunked_arr.push(array.slice(i, i + size))
  }
  return chunked_arr
}

interface Props {
  providers: Provider[]
  jobs: JobAssignment[]
  dayOffTypes: DayOffType[]
}

const TabsWithSearch: React.FC<Props> = ({ providers, jobs, dayOffTypes }) => {
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [activeTab, setActiveTab] = useState<TabName>("Providers")
  const [filteredProviders, setFilteredProviders] =
    useState<Provider[]>(providers)
  const [filteredJobs, setFilteredJobs] = useState<Job[]>(jobs)
  const [filteredDayOffTypes, setFilteredDayOffTypes] =
    useState<DayOffType[]>(dayOffTypes)

  const handleTabClick = (tabName: string) => {
    if (
      tabName === "Providers" ||
      tabName === "Jobs" ||
      tabName === "Day Off Types"
    ) {
      setActiveTab(tabName)
      setSearchTerm("")

      if (tabName === "Providers") {
        setFilteredProviders(providers)
      } else if (tabName === "Jobs") {
        setFilteredJobs(jobs)
      } else if (tabName === "Day Off Types") {
        setFilteredDayOffTypes(dayOffTypes)
      }
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase()
    setSearchTerm(value)

    if (activeTab === "Providers") {
      const filtered = providers.filter(
        (provider) =>
          provider.display_name.toLowerCase().includes(value) ||
          provider.fullname.toLowerCase().includes(value)
      )
      setFilteredProviders(filtered)
    } else if (activeTab === "Jobs") {
      const filtered = jobs.filter(
        (job) =>
          job.abbrev.toLowerCase().includes(value) ||
          job.name.toLowerCase().includes(value)
      )
      setFilteredJobs(filtered)
    } else if (activeTab === "Day Off Types") {
      const filtered = dayOffTypes.filter(
        (type) =>
          type.abbrev.toLowerCase().includes(value) ||
          type.type.toLowerCase().includes(value)
      )
      setFilteredDayOffTypes(filtered)
    }
  }

  return (
    <div className={css.tabContent}>
      <div className={css.tabsWithSearch}>
        <div className={css.tabs}>
          <div
            className={`${css.tab} ${
              activeTab === "Providers" ? css.activeTab : ""
            }`}
            onClick={() => handleTabClick("Providers")}
          >
            Providers
          </div>
          <div
            className={`${css.tab} ${
              activeTab === "Jobs" ? css.activeTab : ""
            }`}
            onClick={() => handleTabClick("Jobs")}
          >
            Jobs
          </div>
          <div
            className={`${css.tab} ${
              activeTab === "Day Off Types" ? css.activeTab : ""
            }`}
            onClick={() => handleTabClick("Day Off Types")}
          >
            Day Off Types
          </div>
        </div>
        <div className={css.search}>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
          />
          <SearchIcon />
        </div>
      </div>
      <div className={css.content}>
        {activeTab === "Providers" && (
          <table className={css.providersTable}>
            <thead>
              <tr>
                <th>Short Name</th>
                <th>Full Name</th>
                <th>Home</th>
                <th>Work</th>
                <th>Fax</th>
                <th>Pager</th>
              </tr>
            </thead>
            <tbody>
              {filteredProviders.map((provider, index) => (
                <tr key={index}>
                  <td>{provider.display_name}</td>
                  <td>{provider.fullname}</td>
                  <td>{provider.home}</td>
                  <td>{provider.work}</td>
                  <td>{provider.fax}</td>
                  <td>{provider.pager}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {activeTab === "Jobs" && (
          <table className={css.providersTable}>
            <tbody>
              {chunk(filteredJobs, 3).map((jobChunk, index) => (
                <tr key={index}>
                  {jobChunk.map((job, idx) => (
                    <td key={idx}>
                      <strong>{job.abbrev}</strong>
                      <br />
                      {job.name}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}{" "}
        {activeTab === "Day Off Types" && (
          <table className={css.providersTable}>
            <tbody>
              {chunk(filteredDayOffTypes, 3).map((typeChunk, index) => (
                <tr key={index}>
                  {typeChunk.map((dayOffType, idx) => (
                    <td key={idx}>
                      <strong>{dayOffType.abbrev}</strong>
                      <br />
                      {dayOffType.type}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}

export default TabsWithSearch
