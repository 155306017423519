import React from "react"
import ProvidersCustomSelectComponent from "../../../ProviderCustomSelect/ProvidersCustomSelect"
import css from "./AdditionalAssignments.module.scss"
import cx from "classnames"
import { Controller, useFieldArray, useForm } from "@app/components/Form"
import {
  MultipleAssignmentForm,
  UnassignedForm,
} from "@app/containers/spa/WhiteboardCalendar/component/JobsTableModal/hooks/jobsTableUseForm"
import { useSelector } from "@app/models"
import AdditionalAssignmentsTimes from "./AdditionalAssignmentsTimes"

interface MultipleProviderDetailProps {
  item: any
  defaultProviders: any[]
  multipleAssignmentSetting: MultipleAssignmentSetting
  value: MultipleAssignmentForm | UnassignedForm
  handleProviderChange: (value: MultipleAssignmentForm) => void
  handleToggleAdditionalAssignments: () => void
}

export type FormFieldsType = {
  providerids: { providerid: number }[]
}

const MultipleProviderDetail: React.FC<MultipleProviderDetailProps> = ({
  item,
  defaultProviders,
  multipleAssignmentSetting,
  value,
  handleProviderChange,
  handleToggleAdditionalAssignments,
}) => {
  const { draft_mode_scheduling } = useSelector(
    (state) => state.calendarEvents.calendarConfig.rulesConfig
  )

  const defaultProviderIds = Array.isArray(value?.data)
    ? value?.data.map((assignment) => ({ providerid: assignment.providerid }))
    : []

  const { control, watch, handleSubmit } = useForm<FormFieldsType>({
    defaultValues: {
      providerids: defaultProviderIds.length
        ? defaultProviderIds
        : [{ providerid: 0 }],
    },
    schema: (yup) =>
      yup.lazy(() =>
        yup.object().shape({
          providerids: yup.array().of(
            yup.object().shape({
              providerid: yup.number().required(),
            })
          ),
        })
      ),
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "providerids",
  })

  const onSubmit = (data: FormFieldsType) => {
    const providersMapped = data.providerids
      .filter((provider) => provider.providerid !== 0)
      .map((provider) => {
        return {
          providerid: provider.providerid,
          jobid: item.jobid,
          edate: item.edate,
        }
      })

    handleProviderChange({
      type: shouldScheduleInDraftMode()
        ? "draft_multiple_assignments"
        : "multiple_assignments",
      data: providersMapped,
    })

    handleToggleAdditionalAssignments()
  }

  const handleMultipleAssignmentProviderChange = (
    onChange: (providerId: number) => void,
    providerId: number
  ) => {
    onChange(providerId)
  }

  const shouldScheduleInDraftMode = () => {
    return item.status === "Published"
      ? false
      : item.status === "Draft"
      ? true
      : draft_mode_scheduling
  }

  const rows = watch("providerids")

  const getSelectedProviders = () => {
    return rows.filter((row) => row.providerid !== 0 && row.providerid !== null)
      .length
  }

  return (
    <>
      <tr className={css.additionalAssignmentPanel}>
        <td />
        <td>
          <div className={css.headers}>Provider(s)</div>
          <div>
            {fields.map((row, index) => {
              return (
                <div key={row.id} className={cx(css.divSelectProvider, "mb-3")}>
                  <Controller
                    key={row.id}
                    name={`providerids.${index}.providerid`}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <ProvidersCustomSelectComponent
                          resetSelectionTrigger={0}
                          jobid={item.jobid}
                          edate={item.edate}
                          onChange={(id) =>
                            handleMultipleAssignmentProviderChange(onChange, id)
                          }
                          defaultProviderId={value === 0 ? "" : String(value)}
                          filterProviderIds={rows
                            .map((provider) => provider.providerid)
                            .filter((providerid) => providerid !== 0)}
                        />
                      )
                    }}
                  />
                </div>
              )
            })}
            {rows.length < multipleAssignmentSetting.maximum &&
              getSelectedProviders() !== 0 && (
                <div className="mt-3 w-75 d-flex justify-content-center">
                  <a
                    onClick={() => append({ providerid: 0 })}
                    className={css.addProviderLink}
                  >
                    + add provider
                  </a>
                </div>
              )}
          </div>
        </td>
        <AdditionalAssignmentsTimes
          jobTimesRange={item.shifttime}
          remove={remove}
          fields={fields}
          onSubmit={handleSubmit(onSubmit)}
          onCancel={handleToggleAdditionalAssignments}
        />
      </tr>
    </>
  )
}

export default MultipleProviderDetail
