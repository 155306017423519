import { parseISO, isSameDay } from "date-fns"

export interface GroupedAssignment extends AssignmentBaseType {
  additional_split_event_assignments?: AssignmentBaseType[]
}

export const getGroupedDayAssignments = (
  assignmentsData: GroupedAssignment[],
  day?: Date
) => {
  let dayAssignments = []
  if (day) {
    dayAssignments =
      assignmentsData?.filter((assignment: AssignmentBaseType) => {
        const assignmentDate = parseISO(assignment.edate)
        return isSameDay(assignmentDate, day)
      }) || []
  } else {
    dayAssignments = assignmentsData
  }

  dayAssignments = dayAssignments.sort((a, b) => {
    const jobA = a.job
    const jobB = b.job

    if (jobA.priority !== jobB.priority) return jobA.priority - jobB.priority
    if (jobA.abbrev !== jobB.abbrev)
      return jobA.abbrev.toLocaleLowerCase() > jobB.abbrev.toLocaleLowerCase()
        ? 1
        : -1
    if (jobA.starttime !== jobB.starttime)
      return jobA.starttime > jobB.starttime ? 1 : -1

    const starttimeA = a.split_shift
      ? `${a.split_shift.edate}T${a.split_shift.starttime}`
      : `${a.edate}T${a.job.starttime}`
    const starttimeB = b.split_shift
      ? `${b.split_shift.edate}T${b.split_shift.starttime}`
      : `${b.edate}T${b.job.starttime}`
    return starttimeA > starttimeB ? 1 : -1
  })

  const groupedAssignments: GroupedAssignment[] = []

  dayAssignments.forEach((assignment) => {
    const assignmentIndex = groupedAssignments.findIndex(
      (groupedAssignment) => groupedAssignment.jobid === assignment.jobid
    )

    if (assignmentIndex > -1 && assignment.split_shiftid) {
      if (
        groupedAssignments[assignmentIndex].additional_split_event_assignments
      ) {
        groupedAssignments[
          assignmentIndex
        ].additional_split_event_assignments?.push({ ...assignment })
      } else {
        groupedAssignments[assignmentIndex].additional_split_event_assignments =
          [{ ...assignment }]
      }
    } else {
      groupedAssignments.push({ ...assignment })
    }
  })

  return groupedAssignments
}
