import React, { useEffect, useState } from "react"
import Table from "@app/components/Table"
import { H5 } from "@app/components/Typography"
import { ProviderDefaultColor } from "@app/utils/constants"
import cx from "classnames"
import css from "./AvailableProviders.module.scss"
import api, { useRequest } from "@app/services/api"
import {
  AvailableUnavailableProviderData,
  FormattedProvider,
  ProviderRules,
} from "./type"

type Props = {
  jobId: number
  edate: string
}

export default ({ jobId, edate }: Props) => {
  const [{ availableProviders, unavailableProviders }, setProviderTableData] =
    useState<AvailableUnavailableProviderData>({
      availableProviders: [],
      unavailableProviders: [],
    })

  const { data: providersWithRules } = useRequest([
    api.getProvidersV3,
    { jobid: jobId, date: edate },
  ])

  const getRulesAppliedMessages = (rulesApplied: ProviderRules) => {
    return rulesApplied.reduce((message, rule) => {
      message += `${rule.message} \n`
      return message
    }, "")
  }

  useEffect(() => {
    if (Array.isArray(providersWithRules)) {
      const formattedProviders = providersWithRules.reduce(
        (
          providerList: AvailableUnavailableProviderData,
          provider: Provider & { rulesApplied: ProviderRules }
        ) => {
          const formattedProvider = {
            id: provider.providerid,
            color: provider.provider_color,
            name: `${provider.display_name} - ${provider.firstname} ${provider.lastname}`,
            available: provider.rulesApplied.length > 0,
          } as FormattedProvider

          if (provider.rulesApplied.length > 0) {
            formattedProvider.violation = getRulesAppliedMessages(
              provider.rulesApplied
            )
            providerList.unavailableProviders.push(formattedProvider)
          } else {
            providerList.availableProviders.push(formattedProvider)
          }

          return providerList
        },
        { availableProviders: [], unavailableProviders: [] }
      )
      setProviderTableData(formattedProviders)
    }
  }, [providersWithRules])

  const availableProvidersColumns = [
    {
      title: "Available Providers",
      dataKey: "name",
      render: (name: string, provider: FormattedProvider) => {
        return (
          <>
            <H5
              ellipsis
              prefixDotColor={provider.color || ProviderDefaultColor}
            >
              {name}
            </H5>
          </>
        )
      },
    },
  ]

  const unavailableProvidersColumns = [
    {
      title: "Unavailable Providers",
      dataKey: "name",
      ellipsis: true,
      render: (name: string, provider: FormattedProvider) => {
        return (
          <>
            <H5
              ellipsis
              prefixDotColor={provider.color || ProviderDefaultColor}
            >
              {name}
            </H5>
          </>
        )
      },
      width: "50%",
    },
    {
      title: "Reason",
      dataKey: "violation",
      width: "50%",
      ellipsis: true,
    },
  ]

  return (
    <>
      <Table
        className={cx("table-bordered", css.ruleFilteredProviders)}
        striped={false}
        data={availableProviders}
        columns={availableProvidersColumns}
      />
      <Table
        className={cx("table-bordered", css.ruleFilteredProviders)}
        striped={false}
        data={unavailableProviders}
        columns={unavailableProvidersColumns}
      />
    </>
  )
}
