import { format, parseISO } from "date-fns"

export const filterUnassignedJobs = (
  jobs: JobAssignment[],
  dayAssignments: AssignmentBaseType[]
): JobUnassigned[] => {
  const assignedJobIds = new Set(dayAssignments.map(({ job }) => job.jobid))
  const assignmentDays = dayAssignments.map(({ edate }) =>
    format(parseISO(edate), "EEEE")
  )

  return jobs
    .filter((job) => {
      if (assignedJobIds.has(job.jobid) || !job.show_empty_assignments) {
        return false
      }
      return job.job_day_types.some(({ assign, day }) => {
        if (assign !== 1) return false
        return assignmentDays.every((assignmentDay) => assignmentDay === day)
      })
    })
    .map(
      ({
        jobid,
        abbrev,
        color,
        job_day_types,
        starttime,
        endtime,
      }: JobUnassigned) => ({
        jobid,
        abbrev,
        color,
        job_day_types,
        starttime,
        endtime,
      })
    )
}

export const getUnassignedJobsByDay = (
  jobs: JobAssignment[],
  date: string,
  dayAssignments: AssignmentBaseType[]
): JobAssignment[] => {
  const dayOfWeek = format(parseISO(date), "EEEE")
  const assignedJobIds = new Set(dayAssignments.map(({ job }) => job.jobid))

  return jobs.filter((job) => {
    if (!job.show_empty_assignments || assignedJobIds.has(job.jobid)) {
      return false
    }
    return job.job_day_types.some(
      (jobDayType) => jobDayType.assign === 1 && jobDayType.day === dayOfWeek
    )
  })
}
