import React, { useEffect } from "react"
import { format, parseISO } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import api from "@app/services/api"
import Button from "@app/components/Button"
import { handleApiError } from "@app/utils"
import Collapse from "@app/components/Collapse"
import { useDispatch, useSelector } from "@app/models"
import { DefaultCalendarColor } from "@app/utils/constants"
import { calculateQuickAssigmentViolations } from "@app/services/quickAssignments"
import { ViolationQuickAssignment } from "@app/containers/spa/WhiteboardCalendar/data"
import css from "./SelectedProviderMenu.module.scss"

export const SelectedProviderMenu = () => {
  const { selectedProvider } = useSelector(
    (state) => state.calendarEvents.calendarConfig
  )

  const { quickAssignments, quickAssignmentViolations } = useSelector(
    (state) => state.quickAssignments
  )

  const dispatch = useDispatch()

  const handleCancel = () => {
    dispatch.calendarEvents.setSelectedProvider(undefined)
    dispatch.calendarEvents.setSelectedJob(undefined)
    dispatch.quickAssignments.resetQuickAssignments()
  }

  useEffect(() => {
    if (selectedProvider) {
      calculateQuickAssigmentViolations(
        quickAssignments,
        selectedProvider,
        (assignmentCount, violations) => {
          dispatch.quickAssignments.updateQuickAssignments({
            assignmentCount,
            quickAssignmentViolations: violations,
          })
        }
      )
    }
  }, [quickAssignments, selectedProvider])

  const handleDeleteQuickAssign = async (
    assignment: ViolationQuickAssignment
  ) => {
    const id = assignment?.draft_eventid || assignment?.eventid
    const deleteAssigment = assignment?.draft_eventid
      ? api.deleteDraftEvent
      : api.deleteEvent

    if (
      assignment.multiple_assignments &&
      assignment.multiple_assignments.length > 0
    ) {
      const multipleAssignments = assignment.multiple_assignments
      const providerIds = multipleAssignments.map((item) => item.providerid)
      const formattedDate = assignment.edate.split("T")[0]
      const filteredProviderIds = providerIds.filter(
        (id) => id !== selectedProvider?.providerid
      )

      const updateFunction = assignment.draft_eventid
        ? api.updateDraftMultipleAssignments
        : api.updateMultipleAssignments

      const response = await updateFunction(
        assignment.jobid,
        formattedDate,
        filteredProviderIds
      )

      const violationIds =
        Number(`${response.draft_eventid}${selectedProvider?.providerid}`) ||
        Number(`${response.eventid}${selectedProvider?.providerid}`)

      dispatch.quickAssignments.removeQuickAssignment(violationIds)
      dispatch.quickAssignments.removeQuickAssignmentAndViolation(violationIds)
      dispatch.calendarEvents.getCalendarData()
    } else {
      if (id) {
        try {
          await deleteAssigment(id)
          dispatch.calendarEvents.getCalendarData()
          dispatch.quickAssignments.removeQuickAssignmentAndViolation(id)
        } catch (error) {
          handleApiError
        }
      }
    }
  }

  const handleUndoAll = () => {
    for (const violation of quickAssignmentViolations) {
      handleDeleteQuickAssign(violation)
    }
  }

  return (
    <aside className={css.container}>
      <div>
        <p className={css.headerText}>Quick Assign mode active for:</p>
        <div className={css.headerWrapper}>
          <p className={css.valueText}>
            <span
              className={css.providerCircle}
              style={{
                background:
                  selectedProvider?.provider_color || DefaultCalendarColor,
              }}
            />
            {selectedProvider?.display_name}
          </p>
          <div>
            <Button
              shape="pill"
              variant="light"
              size="sm"
              onClick={handleCancel}
            >
              Clear
            </Button>
          </div>
        </div>
      </div>

      <div>
        <p className={css.headerText}>Number of assignments</p>
        <span className={css.valueText}>{quickAssignments?.length || 0}</span>
      </div>

      <Collapse title={`Violations (${quickAssignmentViolations.length})`}>
        <div className={css.accordionContainer}>
          <div className={css.buttonRightAlignContainer}>
            <button className={css.undoAllButton} onClick={handleUndoAll}>
              Undo All
            </button>
          </div>
          <ul className={css.violationItemsContainer}>
            {quickAssignmentViolations.map((violation, index) => (
              <li className={css.violationItem} key={index}>
                <span className={css.authorName}>{violation.job.abbrev}</span>
                <span className={css.normalText}>
                  {format(
                    utcToZonedTime(parseISO(violation.edate), "UTC"),
                    "eeee do"
                  )}
                </span>
                <span className={css.normalText}>
                  {violation.rulesApplied[0].message}
                </span>
                <button
                  className={css.undoAssignButton}
                  onClick={() => handleDeleteQuickAssign(violation)}
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        </div>
      </Collapse>
    </aside>
  )
}
