import React, { useMemo } from "react"
import css from "./RequestElement.module.scss"
import { useSelector } from "@app/models"
import cx from "classnames"

interface RequestElementProps {
  request: IRequestType
  setSelectedRequestid: (
    value: React.SetStateAction<number | null | undefined>
  ) => void
}

export const RequestElement = ({
  request,
  setSelectedRequestid,
}: RequestElementProps) => {
  const {
    highlightOptions: { highlightProviders },
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  const providersAreHighlighted = useMemo(
    () => highlightProviders && highlightProviders.length > 0,
    [highlightProviders]
  )

  const isRequestHighlighted = useMemo(
    () =>
      highlightProviders?.some(
        (highlightedProvider) =>
          request.provider.providerid === highlightedProvider.providerid
      ),
    [highlightProviders]
  )

  return (
    <div
      className={cx(css.requestWrapper, {
        [css.highlightedRequest]:
          providersAreHighlighted && isRequestHighlighted,
        [css.fadedRequest]: providersAreHighlighted && !isRequestHighlighted,
      })}
      onClick={() => {
        setSelectedRequestid(request.requestid)
      }}
    >{`${request.request_type} (${request.request_status}) - ${request.provider.display_name}`}</div>
  )
}