import React, { useState, useCallback, useMemo } from "react"
import css from "./FilterProviders.module.scss"
import { Provider, ProviderType } from "../../../../data"
import Avatar from "@app/components/Avatar"
import { H5 } from "@app/components/Typography"
import CheckIcon from "@material-ui/icons/Check"
import api from "@app/services/api"
import useRequest from "@app/utils/request"
import { Select } from "@app/components/Form"
import { useSelector, useDispatch } from "@app/models"
import { DefaultCalendarColor } from "@app/utils/constants"

const initializeSelectedProviders = (
  providersData: Provider[],
  providersIds?: number[]
): Provider[] => {
  if (providersIds === undefined) {
    return []
  } else if (providersIds.length > 0) {
    return providersData.filter((providerItem) =>
      providersIds.includes(Number(providerItem.providerid))
    )
  }
  return providersData
}

interface FilterProviderProps {
  providersData: Provider[]
  onClose: () => void
}

export const FilterProviders = ({
  providersData,
  onClose,
}: FilterProviderProps) => {
  const dispatch = useDispatch()
  const { calendarConfig } = useSelector((state) => state.calendarEvents)

  const {
    filterOptions: { providersFilters },
  } = calendarConfig

  const [providerSubFilters, setProviderSubFilters] = useState<number[]>(
    providersFilters.activeSubFilter ?? []
  )

  const [selectedProviders, setSelectedProviders] = useState<
    Provider[] | undefined
  >(() =>
    initializeSelectedProviders(providersData, providersFilters?.providerIds)
  )

  const providerTypesRequest = useMemo(() => [api.getProviderTypes], [])
  const { data: providerTypes } = useRequest(providerTypesRequest)

  const filteredData = useMemo(
    () =>
      providerSubFilters.length > 0
        ? providersData.filter((item) =>
            providerSubFilters?.includes(item.provider_typeid)
          )
        : providersData,
    [providersData, providerSubFilters]
  )

  const providerTypeOptions = useMemo(
    () =>
      providerTypes?.map((providerType: ProviderType) => ({
        id: providerType.provider_typeid,
        name: providerType.type,
      })),
    [providerTypes]
  )

  const handleProviderSubFilterList = useCallback(
    (selectedTypeIds: number[]) => {
      setProviderSubFilters(selectedTypeIds)

      const currentFilteredData =
        selectedTypeIds.length > 0
          ? selectedProviders?.filter((item) =>
              selectedTypeIds.includes(item.provider_typeid)
            )
          : selectedProviders

      setSelectedProviders(currentFilteredData)
    },
    [selectedProviders]
  )

  const handleSelectedProvider = (provider: Provider) => {
    const isSelected = selectedProviders?.some(
      (selectedProvider) => selectedProvider.providerid === provider.providerid
    )
    if (!isSelected) {
      setSelectedProviders([...(selectedProviders ?? []), provider])
    } else {
      setSelectedProviders(
        selectedProviders?.filter(
          (selectedProvider) =>
            selectedProvider.providerid !== provider.providerid
        )
      )
    }
  }

  const handleApplyFilters = () => {
    dispatch.calendarEvents.saveFilterOptions({
      providersFilters: {
        activeSubFilter: providerSubFilters,
        providerIds: selectedProviders?.map((selectedProvider) =>
          Number(selectedProvider.providerid)
        ),
      },
    })
    onClose()
  }

  const handleCancel = () => {
    setSelectedProviders([])
    onClose()
  }

  const handleSelectAllProviders = useCallback(() => {
    const hasSubFilters =
      providerSubFilters.length > 0 && providerSubFilters.length < 3
    const allProvidersSelected =
      selectedProviders?.length === providersData.length
    const allFilteredProvidersSelected =
      filteredData.length === selectedProviders?.length

    if (hasSubFilters) {
      if (!allFilteredProvidersSelected) {
        setSelectedProviders(filteredData)
      } else {
        setSelectedProviders(undefined)
      }
    } else {
      if (allProvidersSelected) {
        setSelectedProviders(undefined)
      } else {
        setSelectedProviders(providersData)
      }
    }
  }, [providersData, selectedProviders, providerSubFilters, filteredData])

  const selectAllDisplayedText = useMemo(() => {
    const hasSubFilters =
      providerSubFilters.length > 0 && providerSubFilters.length < 3
    const allProvidersSelected =
      selectedProviders?.length === providersData.length
    const allFilteredProvidersSelected =
      filteredData.length === selectedProviders?.length

    if (hasSubFilters) {
      return allFilteredProvidersSelected ? "Unselect All" : "Select All"
    } else {
      return allProvidersSelected ? "Unselect All" : "Select All"
    }
  }, [providersData, selectedProviders, providerSubFilters, filteredData])

  if (!providersData || !providerTypes) {
    return <div>Loading...</div>
  }

  return (
    <div className={css.providerContainer}>
      <div className={css.innerContainer}>
        <Select
          multiple
          value={providerSubFilters}
          options={providerTypeOptions}
          onChange={handleProviderSubFilterList}
          placeholder="All Providers Types"
          className={css.selectMultiProvider}
        />
        <div className={css.selectAllContainer}>
          <div className={css.selectButton} onClick={handleSelectAllProviders}>
            {selectAllDisplayedText}
          </div>
        </div>

        <div className={css.customSelect}>
          <ul>
            {filteredData.length === 0 && <div>No providers found</div>}
            {filteredData.map((provider: Provider) => (
              <li
                key={provider.providerid}
                className={css.customOption}
                onClick={() => handleSelectedProvider(provider)}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    size={25}
                    src={provider.avatar ? provider.avatar : "/images/user.svg"}
                    className="mr-3"
                  />
                  <div
                    className={css.providerCellCircle}
                    style={{
                      background:
                        provider.provider_color || DefaultCalendarColor,
                    }}
                  ></div>
                  <H5 ellipsis>{provider.display_name}</H5>
                </div>
                <div
                  className={`${css.checkboxContainer} ${
                    selectedProviders?.some(
                      (p) => p.providerid === provider.providerid
                    )
                      ? css.checked
                      : css.unchecked
                  }`}
                >
                  {selectedProviders?.some(
                    (p) => p.providerid === provider.providerid
                  ) ? (
                    <CheckIcon className={css.checkIcon} />
                  ) : (
                    <div className={css.uncheckedIcon}></div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={css.buttonContainer}>
        <button onClick={handleCancel} className={css.cancelButton}>
          Cancel
        </button>
        <button onClick={handleApplyFilters} className={css.applyButton}>
          Apply
        </button>
      </div>
    </div>
  )
}
