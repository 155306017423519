import React from "react"
import css from "../JobsTable.module.scss"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import ProvidersCustomSelectComponent from "../../ProviderCustomSelect/ProvidersCustomSelect"
import { SplitShiftForm } from "@app/containers/spa/WhiteboardCalendar/component/JobsTableModal/hooks/jobsTableUseForm"

interface SplitShiftProps {
  item: any
  rowIndex: number
  expandedSplitRows: Record<number, boolean>
  handleProviderChange: (value: SplitShiftForm) => void
  value: SplitShiftForm
  handleClick?: () => void
}

const SplitShiftDetail: React.FC<SplitShiftProps> = ({
  item,
  rowIndex,
  expandedSplitRows,
  handleClick,
  handleProviderChange,
  value,
}) => {
  const { data: splitShiftProviders } = value

  const handleSplitShiftProviderChange = (
    providerId: number,
    rowIndex: number
  ) => {
    const mutatedSplitShifts = [...splitShiftProviders]

    mutatedSplitShifts[rowIndex] = {
      ...mutatedSplitShifts[rowIndex],
      providerid: providerId,
    }

    handleProviderChange({
      type: "split_shift",
      data: mutatedSplitShifts,
    })
  }

  return (
    <div className={css.divSelectProviderMultiple} onClick={handleClick}>
      <span>Split shifts ({item.split_shift.length})</span>
      <ArrowForwardIosIcon
        className={css.arrowIcon}
        style={{
          transform: expandedSplitRows[rowIndex] ? "rotate(90deg)" : "none",
        }}
      />
      {expandedSplitRows[rowIndex] && (
        <div onClick={(e) => e.stopPropagation()}>
          {splitShiftProviders.map((splitShift: any, idx: number) => {
            return (
              <div className={css.divSelectProvider} key={idx}>
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    marginRight: "5px",
                  }}
                ></div>
                <ProvidersCustomSelectComponent
                  resetSelectionTrigger={0}
                  jobid={splitShift?.jobid}
                  edate={splitShift?.edate}
                  onChange={(id: number) =>
                    handleSplitShiftProviderChange(id, idx)
                  }
                  defaultProviderId={splitShift.providerid}
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default SplitShiftDetail
